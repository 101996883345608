import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import HubspotForm from "react-hubspot-form"
import Helmet from "react-helmet"
import AOS from "aos"
import "aos/dist/aos.css"

const Rewards2 = ({ location }) => {
  const [vidWidth, setvidWidth] = useState("")
  const checkScreenWidth = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth <= 415) setvidWidth((90 * window.innerWidth) / 100)
      else if (window.innerWidth <= 768)
        setvidWidth((70 * window.innerWidth) / 100)
      else if (window.innerWidth <= 1466)
        setvidWidth((40 * window.innerWidth) / 100)
      else setvidWidth((30 * window.innerWidth) / 100)
    }
  }

  useEffect(() => {
    checkScreenWidth()
  }, [])

  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])
  return (
    <Layout location={location}>
      <Helmet>
        <title>A Recognition Program that organizations trust.</title>

        <meta
          name="description"
          content="Streamline and automate your employee engagement efforts with our easy-to-use R&R platform that is trusted by over 700+ companies worldwide."
        />
        <meta
          property="twitter:title"
          content="A Recognition Program that organizations trust."
        />
        <meta name="robots" content="noindex" />

        <meta
          property="twitter:description"
          content="Streamline and automate your employee engagement efforts with our easy-to-use R&R platform that is trusted by over 700+ companies worldwide."
        />

        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content="https://www.vantagecircle.com/employee-recognition-program-v3/"
        />

        <meta property="og:site_name" content="Vantage Circle" />
        <meta
          property="og:title"
          content="A Recognition Program that organizations trust."
        />

        <meta
          property="og:description"
          content="Streamline and automate your employee engagement efforts with our easy-to-use R&R platform that is trusted by over 700+ companies worldwide."
        />
        <link
          rel="canonical"
          href="https://www.vantagecircle.com/employee-recognition-program-v3/"
        ></link>
      </Helmet>
      <section class="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0">
        <div class="max-w-7xl grid xl:grid-cols-2 justify-center items-start z-10 lg:px-3 relative">
          <div class="w-full md:w-10/12 xl:w-full mx-auto flex flex-col text-center lg:text-left  h-full justify-between xl:items-start items-center lg:pt-20 lg:pb-44 pt-0 pb-0  px-2">
            {/* <div class="my-2 flex justify-center items-center px-4 py-2 rounded-full bg-indigo-50">
              <img
                decoding="async"
                loading="lazy"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/Rewards-ico-hero.png"
                alt="Vantage Pulse"
                height="15"
                width="15"
              />
              <h2 class="text-purple-100 pl-3 text-sm lato">
                <span className="font-bold">VANTAGE</span> REWARDS
              </h2>
            </div> */}
            <h1 class="text-purple-100 lg:pr-3 xl:w-full text-2rem md:text-4xl xl:text-6xl font-bold text-center xl:text-left">
              A <span class="text-orange">Recognition Program</span> that
              organizations trust!
            </h1>
            <p
              class="my-8 text-lg xl:text-2xl text-purple-100 text-center xl:text-left w-full"
              style={{ lineHeight: "2.2rem" }}
            >
              Streamline and automate your employee engagement efforts with our
              easy-to-use R&R platform that is trusted by over 700+ companies
              worldwide
            </p>
            <div className="xl:flex justify-center items-center lg:justify-start  w-5/6 md:w-1/3 xl:w-5/12  cursor-pointer my-8 z-10">
              <a
                className="vc-new-orange-btn-rounded-full lato rounded-full mb-3 xl:mb-0 xl:mr-2"
                href="#form"
              >
                Learn More
              </a>
            </div>
          </div>
          <div class="w-full md:w-10/12 xl:w-full mx-auto flex flex-col text-center lg:text-left justify-center xl:items-end items-center xl:mr-3 xl:pt-12 xl:pb-20">
            <div
              class="bg-award card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 lg:w-10/12  flex flex-col place-items-center rounded-lg px-6 py-10 perks-bg-2"
              id="form"
            >
              <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 text-center">
                You are now one step away from fostering a dynamic culture of
                recognition
              </h2>
              <div class="w-full flex justify-center items-center lato rewards">
                <div class="flex justify-center items-center">
                  <HubspotForm
                    portalId="6153052"
                    formId="11213e6e-d35f-4b35-9324-95fbd5292961"
                    onSubmit={() => console.log("Submit!")}
                    onReady={form => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-purple-droppurple w-96 h-96 p-96 rounded-full absolute -bottom-72 -right-40 -z-1"></div>
      </section>

      <div class="popup-overlay modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
        <div class="popup-content modal-container bg-white w-11/12 md:max-w-md lg:max-w-6xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <button class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
            <svg
              class="fill-current text-white"
              width="30"
              height="30"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </button>
          <div class="modal-content text-left">
            <div class="aspect-w-16 aspect-h-9">
              {/* <iframe loading="lazy" id = "myIframe" src = "" class="video" title="Vantage Circle Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe> */}
              <iframe
                class="video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/5Y_v1fXaPz0"
                srcdoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/5Y_v1fXaPz0?autoplay=1><img src=https://img.youtube.com/vi/5Y_v1fXaPz0/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Vantage Circle"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* <section id="ratings">
        <div class="w-full pt-20 lg:pt-28 ">
          <div class="max-w-7xl mx-auto px-2 items-center flex flex-col">
            <div class="w-full">
              <h2 class="text-center text-3xl md:text-5xl 2xl:text-5xl text-gray-250">
                Building Culture of recognition at <br />
                <span class="text-orange">700+ Global Organizations</span>
              </h2>
            </div>
            <div class="c-logo-strip w-full py-5 lg:py-10 "></div>
          </div>
        </div>
      </section> */}
      <section class="py-10 xl:py-16 pb-0" id="clientsSection">
        <div class="w-full justify-center items-center flex flex-col py-3">
          <h2 class="text-gray-250 xl:max-w-7xl mx-auto mb-0 font-bold text-center homepage-heading">
            Trusted by <span class="text-orange">700+</span> organizations,
            including Fortune 500s
          </h2>
        </div>
        <div class="w-full justify-center items-center pb-0 flex py-9">
          <img
            src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Web-Client-logos.webp"
            width="1000"
          />
        </div>
      </section>
      <section class="flex w-full place-content-center py-16 lg:py-10 bg-white overflow-x-hidden px-3 lg:px-0">
        <div class="flex flex-col place-items-center max-w-7xl px-3">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-full relative">
            <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
              <h2 class="text-gray-250 text-3xl md:text-4xl xl:text-4xl  font-bold text-center lg:text-left  my-3 w-full">
                <span>Features</span> that meet your
                <br />
                <span class="text-orange"> Rewards & Recognition </span>
                needs
              </h2>
              <p class="text-gray-250 text-lg my-3 leading-8">
                Get started on a flexible platform crafted to align with
                <br />
                your organization's objectives. Begin your journey
                <br />
                today and witness the transformative impact.
              </p>
            </div>
            <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
              <img
                decoding="async"
                loading="lazy"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/Manager-Insights.webp"
                width="500"
                alt="vantage-rewards-manager-insights"
              />
            </div>
          </div>
          <div class="w-full  flex flex-col justify-center items-center mt-5 md:mt-10 py-3 md::py-6 xl:py-0">
            <div class="flex flex-col-reverse lg:grid  lg:grid-cols-2 gap-10 justify-center items-start w-full mb-0 lg:my-8 relative">
              <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                <img
                  decoding="async"
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/Recognition.webp"
                  width="500"
                  alt="vantage-rewards-social-recognition"
                />
              </div>
              <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                <h2 class="text-gray-250 text-3xl md:text-4xl xl:text-4xl  font-bold text-center lg:text-left  my-3 w-full">
                  Acknowledge the efforts of your workforce with{" "}
                  <span class="text-orange">flexible Social Recognition</span>
                </h2>
                <p class="text-gray-250 text-lg my-3 leading-8">
                  Our user-friendly program allows you to cover the recognition
                  needs of a global workforce with flexible monetary and
                  non-monetary rewards. Social recognition enables your
                  employees to acknowledge each other's achievements and
                  contributions, making everyone feel valued and appreciated,
                  thereby fostering a culture of peer-to-peer recognition.
                </p>
              </div>

              {/* <div class="z-0 hidden lg:block absolute -left-24 lg:-top-28 xl:-top-20 2xl:top-0">
                            <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-1.png" />
                        </div> */}
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-full mb-0 lg:my-8 relative">
              <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                <h2 class="text-gray-250  text-3xl md:text-4xl xl:text-4xl font-bold text-center lg:text-left  my-3 w-full">
                  Never miss an opportunity to make your{" "}
                  <span class="text-orange"> employees feel valued</span>
                </h2>
                <p class="text-gray-250 text-lg my-3 leading-8">
                  Appreciation is one of the most powerful ways to motivate your
                  team members. Identify and seize every opportunity to make
                  your team feel valued and motivated, leading to a happier and
                  more productive workplace.
                </p>
              </div>
              <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                <img
                  className="drop-shadow-md"
                  decoding="async"
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1684826117/gatsbycms/uploads/2023/05/EMPLOYEES_FEEL_VALUED.png"
                  width="500"
                  alt="vantage-rewards-manager-insights"
                />
              </div>
              <div class="z-0 hidden lg:block absolute -right-24 bottom-0">
                {/* <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-2.png" /> */}
              </div>
            </div>
            <div class="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-10 justify-center items-start w-full mb-0 lg:my-8 relative">
              <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                <img
                  decoding="async"
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1657697160/gatsbycms/uploads/2023/04/Dashboard.webp"
                  width="500"
                  height="701"
                  alt="vantage-rewards-dashboard"
                />
              </div>
              <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                <h2 class="text-gray-250 text-3xl md:text-4xl xl:text-4xl  my-3 w-full">
                  Streamline your HR processes with our{" "}
                  <span class="text-orange">intuitive Dashboard</span>
                </h2>
                <p class="text-gray-250 text-lg my-3 leading-8">
                  Streamline HR Management, Boost Efficiency! <br />
                  Simplify tasks, save time, and gain insights with our <br />
                  powerful dashboard.
                </p>
              </div>

              {/* <div class="z-0 hidden lg:block absolute lg:-left-20 lg:-bottom-2 2xl:left-20 2xl:-bottom-10">
                            <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-3.png" />
                        </div> */}
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-full mb-0 lg:my-8 relative">
              <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10 ">
                <h2 class="text-gray-250 text-3xl md:text-4xl xl:text-4xl  my-3 w-full">
                  Redeem reward points with our{" "}
                  <span class="text-orange">global Rewards Catalog</span>
                </h2>
                <p class="text-gray-250 text-lg my-3 leading-8">
                  Let your employees explore our Global Rewards Catalog! Access
                  culturally relevant options, locally redeemable choices,and a
                  wide range of gift cards across 1000+ brands,
                  products,experiences,and merchandise across 59+ countries.
                </p>
              </div>
              <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                <img
                  decoding="async"
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1657697163/gatsbycms/uploads/2023/04/Rewards-Catalog.webp"
                  width="500"
                  alt="vantage-rewards-rewards-catalog"
                />
              </div>
              {/* <div class="z-0 hidden lg:block absolute lg:-right-20 lg:-bottom-12 2xl:-right-28">
                            <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-4.png" />
                        </div> */}
            </div>
            <div class="flex flex-col-reverse lg:grid  lg:grid-cols-2 gap-10 justify-center items-start w-full mb-0 lg:my-8 relative">
              <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                <img
                  decoding="async"
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/Service-Milestone.webp"
                  width="500"
                  alt="vantage-rewards-long-service-awards"
                />
              </div>
              <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                <h2 class="text-gray-250 text-3xl md:text-4xl xl:text-4xl  my-3 w-full">
                  Celebrate your employees' loyalty with automated{" "}
                  <span class="text-orange">Long Service Awards</span>
                </h2>
                <p class="text-gray-250 text-lg my-3 leading-8">
                  Honor and recognize loyal employees through long <br />{" "}
                  service awards. Our program offers Anniversary <br /> points,a
                  Service Yearbook, and the choice of a reward <br /> from our
                  Milestone catalog.
                </p>
              </div>

              <div class="z-0 hidden lg:block absolute lg:-left-20 lg:-bottom-16 2xl:-left-28">
                {/* <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-5.png" /> */}
              </div>
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-full mb-0 lg:my-8 relative">
              <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                <h2 class="text-gray-250 text-3xl md:text-4xl xl:text-4xl  my-3 w-full">
                  Enjoy <span class="text-orange">seamless integration </span>
                  into your existing tools for maximum impact
                </h2>
                <p class="text-gray-250 text-lg my-3 leading-8">
                  No change management hassle. Seamlessly integrate <br />
                  with our everyday tools and streamline your employee <br />
                  recognition program for maximum impact and <br />
                  efficiency. We are the preferred partners for <br /> Microsoft
                  Teams, PeopleStrong and more.
                </p>
              </div>
              <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                <img
                  decoding="async"
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/Integrations.webp"
                  alt="vantage-rewards-effortless-integration"
                />
              </div>
              {/* <div class="z-0 hidden lg:block absolute -right-24 bottom-0">
                            <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-2.png" />
                        </div> */}
            </div>
            {/* <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-ful my-12 relative">
                        <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start z-10">
                            <h2 class="text-gray-250 rewardsSectionTitle my-3 w-full">Effortlessly Integration into your existing tools for Maximum Impact</h2>
                            <p class="text-gray-250 text-lg my-3 leading-8">Avoid the hassle of change management and effortlessly integrate with the everyday tools you use. Being the preferred partner for Microsoft Teams, People Strong, and many other HRMS/HCM, Chat Collaboration Tools,  we give you a Seamless Integration experience for , streamlining your employee recognition program and make it even more impactful & efficient.</p>
                        </div>
                        <div class="w-full flex justify-center items-center z-10">
                            <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/04/Group-22.png" />
                        </div>
                        <div class="z-0 hidden lg:block absolute lg:-left-20 lg:-bottom-16 2xl:-left-28">
                            <img decoding="async" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/rewards-stroke-5.png" />
                        </div>
                    </div> */}
          </div>
          <div className="mx-auto w-11/12 md:w-4/12 xl:w-3/12 my-5 py-5">
            <a
              className="vc-new-orange-btn-rounded-full rounded-xl"
              href="#form"
            >
              Request a demo
            </a>
          </div>
        </div>
      </section>
      <section className="bg-indigo-100 w-full flex justify-center py-10 px-5">
        <div className="max-w-7xl xl:flex">
          <div className="flex flex-col items-start justify-center">
            <h2 className="text-3xl xl:text-6xl text-center xl:text-left text-purple-100 font-bold pt-5 mb-8 md:px-12 xl:px-0 xl:w-full">
              Vantage Circle has helped over
              <span className="text-orange"> 700+ global organizations </span>
              build their Recognition Program
            </h2>
            <div class="flex justify-center items-center lg:justify-start w-5/6 mx-auto xl:mx-0 md:w-1/2 lg:w-full xl:w-4/12 cursor-pointer py-5">
              <a
                class="vc-new-orange-btn-rounded-full lato rounded-full w-full"
                href="#form"
              >
                Get started
              </a>
            </div>
          </div>
          <div className="xl:w-full my-5">
            <a href="/case-study/acg/">
              <div
                className="bg-white rounded flex px-7 py-5 w-full my-5"
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-delay="500"
              >
                <div className="w-3/12 flex items-center">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Bitmap-1.png"
                    alt="Infosys Logo"
                    width="80"
                  />
                </div>
                <p className="text-left w-9/12 flex items-center m-0 pl-5 text-gray-250">
                  Infosys improved its employee engagement by 15%
                </p>
              </div>
            </a>
            <a href="/case-study/lti/">
              <div
                className="bg-white rounded flex px-7 py-5 w-full my-5"
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-delay="300"
              >
                <div className="w-3/12 flex items-center">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Bitmap-2-1.png"
                    alt="Infosys Logo"
                    width="70"
                  />
                </div>
                <p className="text-left w-9/12 flex items-center m-0 pl-5 text-gray-250">
                  TATA Motors in achieving 82% rise in peer to peer recognition
                </p>
              </div>
            </a>
            <a href="/case-study/tata-realty/">
              <div
                className="bg-white rounded flex px-7 py-5 w-full my-5"
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-delay="500"
              >
                <div className="w-3/12 flex items-center">
                  <img
                    decoding="async"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/02/Bitmap-3-1.png"
                    alt="Infosys Logo"
                    width="100"
                  />
                </div>
                <p className="text-left w-9/12 flex items-center m-0 pl-5 text-gray-250">
                  TATA Motors in achieving 82% rise in peer to peer recognition
                </p>
              </div>
            </a>
          </div>
        </div>
      </section>
      <section className="pt-10 pb-10 bg-purple-100">
        <div className="max-w-7xl mx-auto px-5">
          <h2 className="text-gray-250 text-3xl xl:text-6xl max-w-3xl mx-auto mt-10 mb-5 font-bold text-center">
            More reasons to choose{" "}
            <span className="text-orange">Vantage Rewards</span>
          </h2>
          <div className="max-w-7xl">
            <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 md:my-20 gap-10 mb-0">
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5"
                data-aos="zoom-in"
              >
                <div className="w-3/12 flex justify-center items-center  transform xl:scale-125">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/easytouse.png"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 flex items-center w-11/12">
                  <p className="text-xl font-semibold text-gray-900 mb-0">
                    Fun and easy <br /> to use
                  </p>
                  {/* <p className='py-6 text-gray-500'>Employee appreciation is more than just words - it's a culture we live and breathe every day. We recognize every effort, celebrate as a team, and ensure everyone feels valued.</p> */}
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5"
                data-aos="zoom-in"
              >
                <div className="w-3/12 flex justify-center items-center  transform xl:scale-125">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/movileweb.png"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 flex items-center w-11/12">
                  <p className="text-xl font-semibold text-gray-900 mb-0">
                    Mobile & web <br /> Application
                  </p>
                  {/* <p className='py-6 text-gray-500'>We understand that work is just one part of your life. We offer flexible schedules and wellness programs to support your physical and mental well-being.</p> */}
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5"
                data-aos="zoom-in"
              >
                <div className="w-3/12 flex justify-center items-center  transform xl:scale-125">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/easy_budgetmanagement.png"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 flex items-center w-11/12">
                  <p className="text-xl font-semibold text-gray-900 mb-0">
                    Easy budget <br /> management
                  </p>
                  {/* <p className='py-6 text-gray-500'>Healthy employees, happy workplace! We provide comprehensive healthcare benefits to prioritize your well-being.</p> */}
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5"
                data-aos="zoom-in"
              >
                <div className="w-3/12 flex justify-center items-center  transform xl:scale-125">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/language-1.png"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 flex items-center w-11/12">
                  <p className="text-xl font-semibold text-gray-900 mb-0">
                    Available in <br /> 16+ languages
                  </p>
                  {/* <p className='py-6 text-gray-500'>By providing financial assistance we prioritize our employees' financial needs as an integral part of our workplace values.</p> */}
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5"
                data-aos="zoom-in"
              >
                <div className="w-3/12 flex justify-center items-center  transform xl:scale-125">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/award.png"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 flex items-center w-11/12">
                  <p className="text-xl font-semibold text-gray-900 mb-0">
                    Panel & Team <br /> Awards
                  </p>
                  {/* <p className='py-6 text-gray-500'>Let's ignite your spark. If you have ideas that can bring a change ; we are game. We acknowledge innovative ideas and mindsets.</p> */}
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5"
                data-aos="zoom-in"
              >
                <div className="w-3/12 flex justify-center items-center  transform xl:scale-125">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/24x_customersupport.png"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 flex items-center w-11/12">
                  <p className="text-xl font-semibold text-gray-900 mb-0">
                    24X5 customer <br /> support
                  </p>
                  {/* <p className='py-6 text-gray-500'>We empower our employees to reach their full potential by offering abundant opportunities for learning and career growth.</p> */}
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5"
                data-aos="zoom-in"
              >
                <div className="w-3/12 flex justify-center items-center  transform xl:scale-125">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/excellence_team.png"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 flex items-center w-11/12">
                  <p className="text-xl font-semibold text-gray-900 mb-0">
                    Center of <br /> Excellence team
                  </p>
                  {/* <p className='py-6 text-gray-500'>We empower our employees to reach their full potential by offering abundant opportunities for learning and career growth.</p> */}
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5"
                data-aos="zoom-in"
              >
                <div className="w-3/12 flex justify-center items-center  transform xl:scale-125">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/product_adoption.png"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 flex items-center w-11/12">
                  <p className="text-xl font-semibold text-gray-900 mb-0">
                    Product adoption <br /> program
                  </p>
                  {/* <p className='py-6 text-gray-500'>We empower our employees to reach their full potential by offering abundant opportunities for learning and career growth.</p> */}
                </div>
              </div>
              <div
                className="flex bg-white rounded-md border border-gray-150 p-5"
                data-aos="zoom-in"
              >
                <div className="w-3/12 flex justify-center items-center  transform xl:scale-125">
                  <img
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/client_success.png"
                    width="30"
                  ></img>
                </div>
                <div className="mx-4 flex items-center w-11/12">
                  <p className="text-xl font-semibold text-gray-900 mb-0">
                    Dedicated client <br /> success team
                  </p>
                  {/* <p className='py-6 text-gray-500'>We empower our employees to reach their full potential by offering abundant opportunities for learning and career growth.</p> */}
                </div>
              </div>
            </div>
            <div className="mx-auto w-11/12 md:w-4/12 lg:w-3/12 xl:w-3/12 my-5 py-5">
              <a
                className="vc-new-orange-btn-rounded-full rounded-xl"
                href="#form"
              >
                Request a demo
              </a>
            </div>
          </div>
          {/* <div className='w-full flex justify-center pb-10'><a href="#form" className='text-orange text-center'>Get our Checklist for Employee Rewards and Recognition now</a></div> */}
        </div>
      </section>
      <section className="w-full bg-indigo-100 px-3 flex flex-col items-center justify-center py-6">
        <div className="xl:grid grid-cols-2 gap-10 justify-center items-center max-w-7xl mt-20">
          <div className="px-3 xl:pl-5">
            <div className="flex justify-start w-full">
              <h2 className="text-3xl text-left md:text-center w-full xl:text-left md:text-4xl pb-8 xl:pb-3 text-purple-100">
                Product Video
              </h2>
            </div>
            <div className="py-3 rounded-md">
              <iframe
                className="prod-vdo mx-auto xl:mx-0 rounded-md"
                width={vidWidth}
                height="300"
                src="https://www.youtube.com/embed/5Y_v1fXaPz0"
                title="Vantage Rewards – Employee Rewards and Digital Gifting Platform | Employee Benefits"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="px-3 xl:border-l border-white xl:pl-10 flex flex-col justify-start pt-10 xl:pt-0">
            <h2 className="text-3xl text-left md:text-center xl:text-left md:text-4xl text-purple-100 xl:pt-0 pt-5 xl:pb-3">
              Grow with us
            </h2>
            <div
              className="flex flex-col justify-center"
              style={{ height: "300px" }}
            >
              <p className="text-purple-100 text-xl md:text-2xl text-left md:text-center xl:text-left">
                Join the bandwagon and deliver a satisfying employee experience
                with our global employee engagement and wellness platform.
              </p>
              <div className="flex pt-10 w-full">
                <div className="w-5/12 xl:w-3/12 flex flex-col items-center xl:items-start">
                  <h2 className="text-4xl xl:text-6xl text-orange">700+</h2>
                  <p className="text-purple-100">Companies</p>
                </div>
                <div className="w-7/12 xl:w-9/12 pl-3 xl:pl-10 border-l border-purple-100 flex flex-col items-center xl:items-start">
                  <h2 className="text-4xl xl:text-6xl text-orange">2M+</h2>
                  <p className="text-purple-100">Corporate Employees</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Rewards2
